<template>
  <a-card :bordered="false">
    <page-header-wrapper :Style="{ padding: '3px 10px'}" :title="false"></page-header-wrapper>
    <a-form layout="inline">
      <a-form-item label="套餐名称">
        <a-input allowClear v-model="queryParam.name" placeholder="请输入套餐名称"/>
      </a-form-item>

      <a-form-item label="状态">
        <a-select v-model="queryParam.status" allowClear style="min-width:100px" placeholder="请选择">
          <a-select-option :value="1">启用</a-select-option>
          <a-select-option :value="2">禁用</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" @click="$refs.table.refresh(true);onClearSelected()">查询</a-button>
      </a-form-item>
      <a-form-item>
        <a-button style="margin-left: 8px" @click="clearSearch()">重置</a-button>
      </a-form-item>
    </a-form>
    <div class="table-operator" align="right">
      <a-button v-action:add icon="plus" type="primary" @click="handleAdd()">新建</a-button>
      <a-dropdown v-if="selectedRowKeys.length > 0" v-action:del>
        <a-menu slot="overlay">
          <a-menu-item key="1" @click="moreDel">
            <a-icon type="delete"/>
            删除
          </a-menu-item>
        </a-menu>
        <a-button>
          批量操作
          <a-icon type="down"/>
        </a-button>
      </a-dropdown>
    </div>
    <div class="ant-alert ant-alert-info" style="margin-bottom: 16px;">
      <i class="anticon anticon-info-circle ant-alert-icon"></i> 已选择&nbsp;<a style="font-weight: 600">{{
        selectedRowKeys.length }}</a>项&nbsp;&nbsp;
      <a style="margin-left: 24px" @click="onClearSelected">清空</a>
    </div>

    <s-table
      ref="table"
      :columns="columns"
      :data="loadData"
      :rowSelection="rowSelection"
      :scroll="{ x: 800,y: 600 }"
      bordered
      rowKey="key"
      size="default"
    >
      <span slot="action" slot-scope="text, record">
        <a v-action:edit @click="handleEdit(record)">编辑</a>
        <a-divider v-action:state type="vertical"/>
        <a v-if="record.status==1" v-action:state @click="handleStatus(record)">禁用</a>
        <a v-if="record.status==2" v-action:state @click="handleStatus(record)">启用</a>
        <a-divider v-action:del type="vertical"/>
        <a v-action:del @click="handleDel(record)">删除</a>

      </span>
    </s-table>
    <a-modal
      v-model="visible"
      :confirmLoading="loading"
      :title="title"
      :width="900"
      @cancel="handleCancel"
      @ok="handleOk"
    >
      <formCreate
        ref="createModal"
        :model="mdl"
      />
    </a-modal>
  </a-card>
</template>

<script>
  import tree from 'vue-giant-tree'
  import { STable } from '@/components'
  import { create, del, FetchList, moreDel, update } from '@/api/setmeal'
  import { formatterTime } from '@/utils/util'
  import formCreate from './from'
  import pick from 'lodash.pick'

  export default {
    components: {
      STable,
      formCreate,
      tree
    },
    data () {
      return {
        check: {
          enable: true
        },
        selectedRowKeys: [],
        selectedRows: [],
        visible: false,
        confirmLoading: false,
        loading: false,
        title: '',
        mdl: null,
        // 查询参数
        queryParam: {
        },
        orgTree: [],
        // 表头
        columns: [
          {
            title: 'ID',
            dataIndex: 'id',
            sorter: true
          },
          {
            title: '套餐名称',
            dataIndex: 'name'
          },
          {
            title: '状态',
            dataIndex: 'status',
            sorter: true,
            customRender: (val) => {
              return val === 1 ? '启用' : '禁用'
            }
          },
          {
            title: '创建时间',
            align: 'center',
            dataIndex: 'createTime',
            sorter: true,
            customRender: (val) => {
              return formatterTime(val)
            }
          },
          {
            title: '更新时间',
            align: 'center',
            dataIndex: 'updateTime',
            sorter: true,
            customRender: (val) => {
              return formatterTime(val)
            }
          },
          {
            title: '操作',
            width: '200px',
            dataIndex: 'action',
            scopedSlots: { customRender: 'action' }
          }
        ],
        // 加载数据方法 必须为 Promise 对象
        loadData: parameter => {
          var searchParam = []

          searchParam = this.getCommonOperate(searchParam, ['=__status', 'like__name'])

          return FetchList(Object.assign(parameter, searchParam))
            .then(res => {
              return res.result
            })
        }
      }
    },
    computed: {
      rowSelection () {
        return {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      }
    },
    methods: {
      getCommonOperate (searchParam, fileds) {
        for (var key in this.queryParam) {
          var value = this.queryParam[key]
          if (typeof value !== 'number') {
            if (value === undefined || value === '' || value === null) {
              continue
            }
            value = value.toString().trim()
            if (value.length === 0) continue
          } else {
            if (value === 0) {
              continue
            }
          }
          for (var j = 0; j < fileds.length; j++) {
            var array = fileds[j].split('__')
            if (array[1] === key) {
              searchParam[fileds[j]] = value
            }
          }
        }
        return searchParam
      },

      handleAdd () {
        this.title = '新增'
        this.mdl = null
        this.visible = true
      },
      handleEdit (record) {
        this.title = '编辑'
        this.visible = true
        this.$nextTick(function () {
          const form = this.$refs.createModal.form
          const fields = ['id', 'status', 'name']
          const datafleld = record
          form.setFieldsValue(pick(datafleld, fields))
        })
      },

      handleOk () {
        const self = this
        const form = this.$refs.createModal.form
        form.validateFields((errors, values) => {
          if (!errors) {
            if (values.id > 0) {
              // 修改 e.g.
              update(values)
                .then(res => {
                  if (res.code === 1) {
                    this.visible = false
                    this.confirmLoading = false
                    // 重置表单数据
                    form.resetFields()
                    // 刷新表格
                    self.$refs.table.refresh()
                    self.$message.info('修改成功')
                  } else {
                    self.$message.error(res.message)
                  }
                })
            } else {
              // 新增
              create(values)
                .then(res => {
                  if (res.code === 1) {
                    this.visible = false
                    this.confirmLoading = false
                    // 重置表单数据
                    form.resetFields()
                    // 刷新表格
                    self.$refs.table.refresh()
                    self.$message.info('新增成功')
                  } else {
                    self.$message.error(res.message)
                  }
                })
            }
          } else {
            this.confirmLoading = false
          }
        })
      },
      handleCancel () {
        this.visible = false
        const form = this.$refs.createModal.form
        form.resetFields() // 清理表单数据（可不做）
      },
      handleDel (record) {
        const self = this
        this.$confirm({
          title: '确定要删除吗？',
          content: '删除后将不可恢复，请谨慎操作！',
          okText: '确认',
          cancelText: '取消',
          onOk () {
            del(record)
              .then(res => {
                if (res.code === 1) {
                  // self.$refs.table.refresh(true)
                  self.$refs.table.refresh(true)
                  self.$message.info(res.message)
                } else {
                  self.$refs.table.refresh(true)
                  self.$message.error(res.message)
                }
              })
          }

        })
      },
      handleStatus (record) {
        const self = this
        this.$nextTick(function () {
          if (record.status === 1) {
            record.status = 2
          } else {
            record.status = 1
          }
          update(record)
            .then(res => {
              // 刷新表格
              self.$refs.table.refresh()
              self.$message.info('修改成功')
            })
        })
      },
      moreDel () {
        const self = this
        const arr = []
        if (this.selectedRows.length > 0) {
          for (let i = 0; i < this.selectedRows.length; i++) {
            arr.push(this.selectedRows[i].id)
          }
          moreDel({ 'ids': arr.join(',') })
            .then(res => {
              if (res.code === 1) {
                self.$refs.table.refresh()
                this.selectedRowKeys = []
                this.selectedRows = []
                self.$message.info(res.message)
              } else {
                self.$message.error(res.message)
              }
            })
        }
      },
      onSelectChange (selectedRowKeys, selectedRows) {
        this.selectedRowKeys = selectedRowKeys
        this.selectedRows = selectedRows
      },
      onClearSelected () {
        this.selectedRowKeys = []
        this.selectedRows = []
      },
      clearSearch () {
        this.queryParam = {}
        this.onClearSelected()
      }
    }
  }
</script>
